<template>
    <b-overlay>
        <b-row>
            <b-col md="1" class="font-weight-bold">Ime:</b-col>
            <b-col md="1">{{ userData.name }}</b-col>
        </b-row>
        <b-row>
            <b-col md="1" class="font-weight-bold">Email:</b-col>
            <b-col md="1">{{ userData.email }}</b-col>
        </b-row>
        <div class="mt-2">
            <h3>Sprememba gesla</h3>
            <b-form-input type="password" v-model="password" class="col-sm-2"/>
            <b-button class="mt-1" variant="primary" @click="changePassword">Spremeni</b-button>
        </div>
    </b-overlay>
</template>

<script>
    export default {
        data() {
            return {
                userData: {},
                password: ''
            }
        },
        methods: {
            changePassword() {
                const thisIns = this
                if (thisIns.password !== '') {
                    this.$http.patch(`/api/v1/management/user/${thisIns.userData.id}`, {password: thisIns.password})
                        .then(function() {
                            thisIns.password = ''
                            thisIns.$printSuccess('Geslo je spremenjeno')
                        }).catch(function(error) {
                            thisIns.$printWarning(`Napaka! Koda: ${error.response.status}`)
                        })
                } else thisIns.$printWarning('Vpišite geslo!')
            }
        },
        created() {
            this.userData = JSON.parse(sessionStorage.getItem('userData'))
        }
    }
</script>
